.whole-page-main-div {
    background-color: rgb(243, 245, 246);
}

.single-photo {
    max-width: 1024px;
    max-height: 768px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    display: flex;
    padding-top: 75px;
    padding-bottom: 25px;
    /* z-index: 2; */
    justify-content: center;
    align-items: center;
}

/* 33, 33, 36 */

.photo-background {
    background-color: rgb(33, 33, 36);
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;

}

.photo-info-box {
    /* max-width: 500px; */
    overflow: auto;

}

.single-photo-main-box {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

}





.delete-photo-button {
    width: 200px;
    border-radius: 5px;
    border: 0px;
    padding: 10px;
    background-color: rgb(18, 143, 220);
    color: white;
    margin: 5px;
}

.submitButton {
    width: 200px;
    border-radius: 5px;
    border: 0px;
    padding: 10px;
    background-color: rgb(18, 143, 220);
    color: white;
    margin: 5px;
}

/* .comments {
    position: relative;
    left: 550px;
    bottom: 25vh;
    width: 500px;
} */



.edit-delete-main {
    display: flex;
    /* flex-direction: row-reverse; */
    justify-content: center;
    justify-items: center;
    align-items: center;
    background-color: rgb(243, 245, 246);

    /* right: 3%; */
    /* position: absolute; */


}

input.editInput {

    width: 100%;
    overflow: visible;
    margin-top: 5px;
    height: auto;
    padding: 0px;
    border-radius: 2px;
    border-color: rgba(128, 128, 128, 0.308);

}

.edit-box-div {
    display: block;

    width: 100%;

}

textarea.editInput {
    -webkit-scrollbar-display: none;
    display: block;
    margin-top: 5px;
    width: 100%;
    height: 100px;
    overflow-x: hidden;
    overflow-y: hidden;
    padding: 0px;
    border-radius: 2px;
    border-color: rgba(128, 128, 128, 0.308);

}

.char-counter-post {
    margin-bottom: 10px;
    margin-top: 5px;
    font-size: 12px;
}

.create-comment-errors {
    font-size: 14px;
}

.error-list {
    margin-bottom: 10px;
    color: red;
}


.left-side {
    width: 45%;
}

.center-div {
    width: 10%;

}

.right-side {
    width: 45%;

}



.edit-photo-form {
    justify-content: center;

}

.edit-photo-error {
    color: red;
    font-size: 12px;
    height: 40px;
    justify-items: center;
    display: flex;
    justify-content: center;
}

.edit-button-div {
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
}

.tippity-top {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-bottom: 20px;


}

.taken-on {
    padding-bottom: 20px;
    font-size: 16px;
    font-weight: 800;
}

.number-comments-date {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.number-comments {
    display: flex;
    justify-content: center;
    justify-items: center;
    font-size: 20px;
    font-weight: 800;
    line-height: 18px;
    padding-bottom: 10px;

}

.comments-count-text {
    font-size: 12px;
    text-align: center;

}

.number-likes {
    display: flex;
    justify-content: center;
    justify-items: center;
    font-size: 20px;
    font-weight: 800;
    line-height: 18px;
    padding-bottom: 10px;

}

.number-likes-text {
    font-size: 12px;
    text-align: center;


}


.camera-specs {
    border-top: 1px solid rgba(0, 0, 0, 0.288);
    padding-top: 20px;
}

button.button {
    /* position: relative; */

    font-size: 42px;
    height: 42px;
    color: white;
    text-align: center;
    line-height: 42px;
    vertical-align: middle;
    background-color: transparent;
    border-color: transparent;
    /* border: 1px black solid; */
}

button.button:hover {
    cursor: pointer;
}

.likes-button-container {
    display: flex;
    justify-content: flex-end;
    /* justify-items: flex-end;
    align-items: flex-end; */
    width: 90%;
    position: relative;
    top: -60px;
    height: 42px;
}


.likes-and-comments {
    display: flex;
    flex-direction: row;
}

.likes-container {
    padding-right: 10px;

}

.comments-stats-container {
    padding-left: 10px;
}

.followContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 20px;
}

.unfollowBtn {
    width: 120px;
    height: 25%;
    border-radius: 5px;
    align-self: center;
    border: 0px;
    padding: 10px;
    background-color: white;
    color: rgb(18, 143, 220);
    border: 1px solid rgb(18, 143, 220);
    margin: 5px;
    cursor: pointer;
    margin-left: 20px;
}

.unfollowBtn:hover {
    color: black;
    cursor: pointer;
    border: black 1px solid;
}

.followBtn {
    width: 100px;
    height: 25%;
    border-radius: 5px;
    align-self: center;
    border: 0px;
    padding: 10px;
    background-color: rgb(18, 143, 220);
    color: white;
    margin: 5px;
    cursor: pointer;
    margin-left: 20px;
}

.followBtn:hover {
    color: black;
    cursor: pointer;
}

.authorFollowers {
    /* width: 10%; */
    display: flex;
    align-items: center;
    font-size: 18px;
    color: gray
}

.user-name-prof {
    display: flex;
    flex-direction: row;
}
