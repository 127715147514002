.aboutPageContainer {
    margin-top: 100px;
    font-size: 50px;
    font-weight: 700;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.aboutPageHeader {
    margin-top: 20px;
    margin-bottom: 20px;

}

.thanks {
    font-size: 18px;
}

.teamIndividuals {
    margin-top: 50px;
    height: 50%;
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.playerCard {
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.indiImage {
    width: 50%;
    height: 50%;
    border-radius: 100%;
}

.indiName {
    /* width: 60%;
    height: 50%; */
    font-size: 32px;
    padding: 10px;
    text-align: center;

}

.nameContainer {
    margin-top: 20px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.socialLinks {
    /* width: 55%;
    height: 20%; */
    padding-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    /* border: 1px black solid; */
}

.gitHub {
    width: 100%;
    height: 100%;
    /* border: 1px red solid; */
}

.linkedin {
    width: 100%;
    height: 100%;
    /* border: 1px green solid; */
}

a.aboutPage {
    width: 22.5%;
    height: 50%;
    margin-top: -10px
}
