.nav {
    background-color: rgb(33, 33, 36, .95);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 48px;
    z-index: 1;
}

.logo-props {
    height: 25px;
    width: 50px;
}

.username-display {
    vertical-align: middle;
    color: white;
}


label {
    color: white;
    font-size: 24px;
    font-weight: bolder;
    text-align: center;
}

.nav-right {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    padding-top: 5px;
    padding-right: 25px;

}

.Home {
    padding-top: 10px;
    padding-left: 25px;
    display: flex;
    align-content: center;
}

.login {
    color: white;
    text-decoration: none;
    /* padding-top: 10px; */
    padding-right: 25px;
    padding-left: 25px;
}

a.login:hover {
    color: rgb(233, 223, 223);
}

.sign-up {
    color: black;
    text-decoration: none;
}

.sign-button {
    border: none;
    border-radius: 3px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    font-family: Arial;
    font-size: 12px;
    font-weight: 600;
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    text-decoration: none;
    transition: all .25s ease-in-out;
    vertical-align: top;
}

.picture-this {
    cursor: pointer;
    text-decoration: none;
    align-content: center;
    position: relative;
    top: -4px;
}

.picture-this:visited {
    cursor: pointer;
    text-decoration: none;

}

a.create-a-photo {
    color: black;
}

.createPhotoButton:hover {
    background-color: grey;
}



.about-me-nav {
    /* position: absolute;
    top: 0;
    right: 50%;
    left: 50%;
    width: 100px; */
    display: flex;
    /* flex-direction: row; */
    justify-content: center;
    align-items: center;
    height: 38px;
    /* padding-top: 5px; */
    padding-right: 25px;
    /* padding-left: auto; */
    color: white;
    text-decoration: none;
}

a.aboutMe {
    color: white;
    text-decoration: none;
}

a.aboutMe:hover {
    color: rgb(233, 223, 223);
    text-decoration: none;
}

.logout:hover {
    background-color: grey;
}


.explore-link {
    color: white;
    display: flex;
    text-decoration: none;
    vertical-align: center;
    text-align: center;
    height: 38px;
    padding-left: 60px;
    padding-top: 5px;
}

a.explore-link:hover {
    color: rgb(233, 223, 223);
    text-decoration: none;
}
