/* TODO Add site wide styles */

li {
   list-style-type: none;
}

html {
   width: 100%;
   height: 100%;
   margin: 0;
   padding: 0;
   font-family: 'Montserrat', sans-serif;
}

body {
   width: 100%;
   height: 100%;
   margin: 0;
   padding: 0;
   font-family: 'Montserrat', sans-serif;
}

*{
   font-family: 'Montserrat', sans-serif;
}

label {
   text-decoration: none;
}

button {
   cursor: pointer;

}
