.main {
    justify-content: center;
    align-items: center;
    display: flex;
}

.login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    /* left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute; */
    height: auto;
    opacity: 0.9;
    background-color: white;
    /* min-width: 500px; */
    font-family: Arial;
    border: 1px grey solid;
    border-radius: 10px;

}

.login-buttons {
    position: relative;
    font-weight: 12px;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    border: 0px;
    padding: 10px;
    margin-top: 10px;
    background-color: rgb(18, 143, 220);
    min-width: 15vw;
    color: rgb(255, 255, 255);
    /* border: grey 1px solid; */
}

.login-head {
    font-weight: lighter;
    justify-content: center;
    align-items: center;
    display: flex;
}

input {
    /* position: relative; */
    font-weight: 12px;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    display: flex;

    /* display: flex;
    justify-content: center;
    align-items: center; */
    /* text-align: center; */
    border-radius: 5px;
    min-width: 400px;
    /* width: 100%; */
    height: 100%;
    padding: 10px;
    border: 1px;
    border-radius: 5px;
    border: grey 1px solid;
    margin-bottom: 10px;
}

.not-member {
    font-size: 12px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.background-img {
    background-image: url('../auth/208106.jpg');
    height: 100vh;
    width: 100vw;
    /* padding: 0px; */
    /* left: 0;
    position: absolute;
    z-index: -2; */
    justify-content: center;
    align-items: center;
    display: flex;
}

.login-errors {
    color: red;
    font-size: 12px;
    padding-bottom: 10px;
    text-align: center;
    justify-content: center;
}

textarea.create-photo-input {
    -webkit-scrollbar-display: none;
    display: block;
    /* padding-top: 5px; */
    /* width: 100%; */
    height: 100%;
    min-width: 400px;
    min-height: 200px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 10px;
    /* margin-top: 10px; */

}

.aws-photo-upload-container{
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    width: 400px;

}

input.file_pathInput {
    /* position: relative;
    font-weight: 12px; */

    display: none;
    /* justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    border: 0px;
    padding: 10px;
    margin-top: 10px;
    background-color: rgb(18, 143, 220);
    min-width: 15vw;
    color: rgb(255, 255, 255); */

}

img.photo-preview {
    height: 25%;
    width: 25%;
    /* justify-items: center;
    justify-content: center; */

}

label.aws {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    justify-items: center;
    align-items: center;
    width: 50px;
    color: black;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    border: 0px;
    padding: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    background-color: rgb(18, 143, 220);
    min-width: 10vw;
    color: rgb(255, 255, 255);
    position: relative;
    font-weight: lighter;
    cursor: pointer;

}

.submitButton {
    cursor: pointer;

}
