.main-404-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    align-content: center;
    text-align: center;
    position: relative;
    height: 100%;
    width: 100%;
}

.main-404 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    margin-top: 100px;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    position: relative;
    height: 100%;
    width: 100%;
    font-size: 24px;
    padding-bottom: 25px;
}

.navlink-photos{
    font-size: 20px;
    text-decoration: none;
}

.navlink-photos:hover {
    cursor: pointer;
}
