.comments-div {
    border-top: 1px solid rgba(0, 0, 0, 0.288);
    margin-top: 10px;
}

li.comment-name {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 30px;
    margin-top: 30px;
}

li.comment-body {
    margin-top: 10px;
    font-size: 12px;
    font-weight: lighter;
    width: 100%;
    word-break: break-all;
    /* overflow-wrap: normal; */
}

/* .edit-box-div {
    width: 500px;
}

div.comments {
    width: 500px;
} */

.post-comment-button {
    margin-bottom: 75px;
    width: 100px;
    border-radius: 5px;
    border: 0px;
    padding: 10px;
    background-color: rgb(18, 143, 220);
    color: white;
    margin-top: 5px;
}

.edit-comment-button {
    margin-bottom: 10px;
    width: 200px;
    border-radius: 5px;
    border: 0px;
    padding: 10px;
    background-color: rgb(18, 143, 220);
    color: white;
    margin-top: 5px;
}

.fa.fa-trash:before {
    font-size: 20px;
    margin-left: 10px;
}

.char-counter {
    margin-bottom: 10px;
    margin-top: 5px;
}

.create-comment-input {
    border: 1px solid #ccc;
    /* font-family: inherit;
    font-size: inherit; */
    padding: 0;
    font-size: 12px;
    display: block;
    width: 100%;
    height: 175px;


    min-height: 40px;
    line-height: 20px;

    /* height: auto;
    display: flex;
    width: 100%;
    overflow: visible; */

}
