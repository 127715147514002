.landing-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-self: center;
    width: 500px;
    height: 100vh;
    margin-top: auto;
    margin-bottom: auto;
    position: relative;
    /* top: 50%; */
    /* left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute; */
    /* height: 30vh; */
    /* opacity: 0.9; */
    background-color: transparent;
    min-width: 500px;
    font-family: Arial;
    color: white;
    font-weight: bold;
    font-size: 1.2rem;
}

.start-button {
    border: none;
    border-radius: 3px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    font-family: Arial;
    font-size: 18px;
    font-weight: 600;
    padding: 12px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    text-decoration: none;
    transition: all .15s ease-in-out;
    vertical-align: top;
}
.main-landing {
    /* background-image: url('https://wallpapercave.com/wp/wp7451611.jpg');
    height: 100vh;
    min-width: 1903px;
    padding: 0px;

    justify-content: center;
    align-items: center;
    display: flex; */

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    position: relative;
    /* top: 80px; */
    border: none;
    background-image: url(https://images.pexels.com/photos/417074/pexels-photo-417074.jpeg);
    background-size: cover;
    animation: change 25s infinite ease-in-out;
    font-family: 'Big Shoulders Display', cursive;
    font-family: 'Fraunces', serif;
    font-family: 'Lexend Deca', sans-serif;
    font-family: 'Montserrat', sans-serif;

}


@keyframes change {

    0% {
        background: url(https://images.pexels.com/photos/417074/pexels-photo-417074.jpeg);
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        -webkit-backface-visibility: hidden;
    }

    25% {
        background: url(https://images.hdqwalls.com/download/forest-landscape-mountain-nature-river-scenic-4k-lq-1920x1080.jpg);
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        -webkit-backface-visibility: hidden;
    }

    50% {
        background: url(https://images.hdqwalls.com/download/lighthouse-spring-od-1920x1080.jpg);
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        -webkit-backface-visibility: hidden;
    }

    75% {
        background: url(https://wallpaperaccess.com/full/255959.jpg);
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        -webkit-backface-visibility: hidden;
    }

    100% {
        background: url(https://images.pexels.com/photos/417074/pexels-photo-417074.jpeg);
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        -webkit-backface-visibility: hidden;
    }

}
