.main {
    justify-content: center;
    align-items: center;
    display: flex;
}

.login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25%;
    /* left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute; */
    height: auto;
    opacity: 0.9;
    background-color: white;
    min-width: 500px;
    font-family: Arial;
    border: 1px grey solid;
    border-radius: 10px;

}

.login-buttons {
    position: relative;
    font-weight: 12px;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    border: 0px;
    padding: 10px;
    margin-top: 10px;
    background-color: rgb(18, 143, 220);
    min-width: 15vw;
    color: rgb(255, 255, 255);
    /* border: grey 1px solid; */
}

.login-head {
    font-weight: lighter;
    justify-content: center;
    align-items: center;
    display: flex;
}

input {
    position: relative;
    font-weight: 12px;
    justify-content: center;
    align-items: center;
    display: flex;

    display: flex;
    justify-content: center;
    align-items: center;
    /* text-align: center; */
    border-radius: 5px;
    min-width: 14vw;
    padding: 10px;
    border: 1px;
    border-radius: 5px;
    border: grey 1px solid;
}

.not-member {
    font-size: 12px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.background-img {
    background-image: url('./208106.jpg');
    background-size: cover;
    height: 100vh;
    width: 100%;
    /* padding: 0px; */
    /* left: 0;
    position: absolute;
    z-index: -2; */
    justify-content: center;
    align-items: center;
    display: flex;
}

.login-errors {
    color: red;
    font-size: 12px;
    padding-bottom: 10px;
    text-align: center;
    justify-content: center;
}
