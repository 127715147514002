  .explore-photo-container {
    padding: 40px 5%;
  }

  ul {
    list-style: none;
  }

  .image-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .image-gallery > li {
    flex: 1 1 auto;
    height: 300px;
    cursor: pointer;
    position: relative;
  }

  .image-gallery::after {
    content: "";
    flex-grow: 999;
  }

  .image-gallery li img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    border-radius: 5px;
  }


  